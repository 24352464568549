import React from 'react';

import PdfPreview from '../../components/pdf-preview/pdf-preview';

import * as pdfData from '../../../static/schooling/szkolenia_dla_osob_indywidualnych.pdf';

class DlaOsobIndywidualnychPage extends React.Component {
    render() {
        return (
            <PdfPreview pdfData={pdfData}></PdfPreview>
        )
    }
}

export default DlaOsobIndywidualnychPage;